<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { saleDetailListAPI, sumSaleDetailListAPI } from "@/api/sale/report"; //销售报表

export default {
  name: "saleDetail",
  components: { TablePage },
  data() {
    return {
      options: {
        // mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        uuid:'e45be81e-50c5-936a-19be-dbd67b51cc7c',
        getListApi: saleDetailListAPI,
        getSummaryApi: sumSaleDetailListAPI,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        labelWidth: "80",
        check: [], // 选中数据
        exportOption: {
          exportApi: "exportSaleDetail",
          exportName: "销售明细表",
        },
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            tip: "单据编号、商品编号、商品名称、仓库编号、仓库名称、客户编号、客户名称",
            model: "",
            filter: "query",
          },
          this.$select({
            key: "billTypes",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "partnerFun",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurCreateEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurAuditEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSaleEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          {
            type: "input",
            label: "收货人",
            model: "",
            filter: "consigneeNames",
            seniorSearch: true,
          },
          {
            type: "input",
            label: "收货电话",
            model: "",
            filter: "consigneeTels",
            seniorSearch: true,
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 120,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户",
            minWidth: 160,
          },
          {
            prop: "consigneeName",
            label: "收货人",
            minWidth: 160,
          },
          {
            prop: "consigneeTel",
            label: "联系电话",
            minWidth: 160,
          },
          {
            prop: "consigneeAddress",
            label: "地址",
            minWidth: 160,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 160,
          },
          {
            prop: "categoryName",
            label: "商品类别",
            minWidth: 160,
          },
          {
            prop: "goodsNo",
            label: "商品编号",
            minWidth: 160,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 160,
          },
          {
            prop: "barcode",
            label: "商品条码",
            minWidth: 160,
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 160,
          },
          {
            prop: "storeName",
            label: "仓库",
            minWidth: 160,
          },
          {
            prop: "basUnitName",
            label: "基本单位",
            minWidth: 160,
          },
          {
            prop: "basUnitQty",
            label: "基本单位数量",
            minWidth: 120,
          },
          {
            prop: "unit",
            label: "单位",
            minWidth: 120,
          },
          {
            prop: "unitQty",
            label: "数量",
            minWidth: 150,
          },
          {
            prop: "unitPrice",
            label: "单价",
            minWidth: 150,
          },
          {
            prop: "unitTaxPrice",
            label: "含税单价",
            minWidth: 150,
          },
          {
            prop: "taxRate",
            label: "税率",
            minWidth: 150,
          },
          {
            prop: "unitTaxMoney",
            label: "税额",
            minWidth: 150,
          },
          {
            prop: "unitPriceTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 150,
          },
        ],
        summary: ["basUnitQty", "unitQty", "unitTaxMoney", "unitPriceTaxMoney"],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      let url = "/api/system/purchase/bill/updateBillStatus";
      let billType = "110101";
      switch (type) {
        case "routerLink":
          {
            const objName = {
              120102: "SaleOutDetail",
              120103: "SaleBackDetail",
            };
            // const billId = row.billId
            this.$router.push({
              name: objName[row.billType],
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData, url, billType);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
  },
};
</script>
<style lang="scss" scoped></style>
