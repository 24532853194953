<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  saleDailyReportAPI,
  saleDailyReporYeartAPI
} from '@/api/sale/analyse/saleDaily' //销售毛利分析

export default {
  name: 'saleDaily',
  components: { TablePage },
  data () {
    return {
      options: {
        // mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        uuid:'a84eb9d5-38b0-e5d9-ffa5-f5ea88d64229',
        getListApi: saleDailyReportAPI,
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        labelWidth: "80",
        check: [], // 选中数据
        search: [
          {
            label: '统计维度',
            type: 'local',
            model: '',
            filter: 'billTypes',
            option: {
              data: [
                { value: 'day', label: '按日统计' },
                { value: 'month', label: '按月统计' }
              ],
              label: 'label',
              value: 'value',
              filterable: true,
              // multiple: true,
            }
          },
          {
            label: '日期',
            type: 'daterange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          // {
          //   prop: 'userId',
          //   label: '序号',
          //   minWidth: 80
          // },
          {
            prop: 'billDateStr',
            label: '日期',
            minWidth: 155
          },
          {
            prop: 'saleOrderNumber',
            label: '销售订单数',
            minWidth: 120,
          },
          {
            prop: 'saleOrderMoney',
            label: '销售订单金额',
            minWidth: 120
          },
          {
            prop: 'saleOutOrderNumber',
            label: '销售出库单单数',
            minWidth: 120
          },
          {
            prop: 'saleOutOrderMoney',
            label: '销售出库单金额',
            minWidth: 160
          },
          {
            prop: 'saleRefundOrderNumber',
            label: '销售退货单单数',
            minWidth: 160
          },
          {
            prop: 'saleRefundOrderMoney',
            label: '销售退货单金额',
            minWidth: 160
          },
          {
            prop: 'saleGain',
            label: '销售毛利',
            minWidth: 160
          },
          {
            prop: 'gainRate',
            label: '毛利率%',
            minWidth: 160
          },
        ],
      }
    }
  },
  methods: {
    seniorSearch () {},
  }
}
</script>
<style lang="scss" scoped></style>
